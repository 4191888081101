import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { GlobalStyle } from '../../styles/StyledComponents';
import Navigation from '../../components/Navigation';
import '../../index.css';
import reportWebVitals from '../../reportWebVitals';
import SSCCGLTestSelector from './SSCCGLTestSelectorPage';

const SscHomePage = lazy(() => import('./HomePage'));
const ExamPage = lazy(() => import('../../pages/ExamPage'));
const MockTestPage = lazy(() => import('../../pages/MockTestPage'));
const ResultsPage = lazy(() => import('../../pages/ResultsPage'));
const LoginPage = lazy(() => import('../../pages/LoginPage'));
const SignupPage = lazy(() => import('../../pages/SignupPage'));
const About = lazy(() => import('../../pages/About'));
const Terms = lazy(() => import('../../pages/Terms'));
const Privacy = lazy(() => import('../../pages/Privacy'));

function App() {
  return (
    <>
      <GlobalStyle />
      <Navigation />
      <Suspense fallback={<div>Loading...</div>}>
        <Outlet />
      </Suspense>
    </>
  );
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { index: true, element: <SscHomePage /> },
      { path: 'ssc-cgl', element: <SSCCGLTestSelector  /> },
      { path: 'exam/:examId', element: <ExamPage /> },
      { path: 'mocktest/:testId', element: <MockTestPage /> },
      { path: 'results/:testId', element: <ResultsPage /> },
      { path: 'login', element: <LoginPage /> },
      { path: 'signup', element: <SignupPage /> },
      { path: 'about', element: <About /> },
      { path: 'terms', element: <Terms /> },
      { path: 'privacy', element: <Privacy /> },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
