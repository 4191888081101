import ExamTestSelector from '../ExamTestSelector';

const SSCCGLTestSelectorPage = () => {
  const sscCGLData = {
    examName: "SSC-CGL",
    examAbbreviation: "SSC-CGL",
    introText: "Welcome to our comprehensive SSC-CGL Mock Test platform! We offer a wide range of practice tests designed to help you excel in your SSC-CGL exam preparation. Our mock tests are based on previous years' papers, providing you with the most authentic and relevant practice material. Choose from full-length mock tests or focus on specific sections to refine your skills. Start your journey to success today!",
    whyOurTests: {
      intro: "Our mock tests are crafted from actual previous years' SSC-CGL question papers, making them the most effective way to prepare for your upcoming exam. Here's why they're invaluable:",
      points: [
        "Authentic Experience: Get a feel for the real exam with questions that mirror the actual SSC-CGL format and difficulty level.",
        "Comprehensive Coverage: Our tests span all subjects and topics, ensuring you're well-prepared for every aspect of the exam.",
        "Performance Analysis: Receive detailed feedback on your performance, helping you identify strengths and areas for improvement.",
        "Time Management Practice: Simulate real exam conditions to improve your speed and accuracy under pressure."
      ]
    },
    aboutExam: {
      intro: "The Staff Selection Commission - Combined Graduate Level (SSC-CGL) exam is a prestigious government recruitment test in India. It's conducted in multiple tiers:",
      structure: [
        "Tier 1: Computer-based test covering General Intelligence & Reasoning, General Awareness, Quantitative Aptitude, and English Comprehension.",
        "Tier 2: Computer-based descriptive paper testing quantitative abilities, English language, and statistics.",
        "Tier 3: Descriptive paper to test writing skills in English/Hindi.",
        "Tier 4: Computer Proficiency Test/Data Entry Speed Test (wherever applicable)."
      ],
      outro: "Success in the SSC-CGL exam can lead to various prestigious positions in government departments and ministries. Regular practice with our mock tests will significantly boost your chances of achieving a top rank."
    },
    motivationText: "Remember, consistent effort is the key to success. Every mock test you take brings you one step closer to your goal. Stay motivated, stay focused, and believe in yourself. Your dedication today will shape your future tomorrow. Keep pushing your limits, and success will surely follow!",
    testData: {
      Tier1: {
        fullTests: Array.from({ length: 25 }, (_, i) => ({ id: `t1-full-${i + 1}`, name: `Tier 1 Full Mock Test ${i + 1}` })),
        sections: [
          { name: 'Aptitude', tests: Array.from({ length: 25 }, (_, i) => ({ id: `t1-apt-${i + 1}`, name: `Aptitude Test ${i + 1}` })) },
          { name: 'Reasoning', tests: Array.from({ length: 25 }, (_, i) => ({ id: `t1-rea-${i + 1}`, name: `Reasoning Test ${i + 1}` })) },
          { name: 'English', tests: Array.from({ length: 25 }, (_, i) => ({ id: `t1-eng-${i + 1}`, name: `English Test ${i + 1}` })) },
          { name: 'General Knowledge', tests: Array.from({ length: 25 }, (_, i) => ({ id: `t1-gk-${i + 1}`, name: `General Knowledge Test ${i + 1}` })) },
        ],
      },
      Tier2: {
        fullTests: Array.from({ length: 25 }, (_, i) => ({ id: `t2-full-${i + 1}`, name: `Tier 2 Full Mock Test ${i + 1}` })),
        sections: [
          { name: 'Quantitative Abilities', tests: Array.from({ length: 25 }, (_, i) => ({ id: `t2-qa-${i + 1}`, name: `Quantitative Abilities Test ${i + 1}` })) },
          { name: 'English Language', tests: Array.from({ length: 25 }, (_, i) => ({ id: `t2-el-${i + 1}`, name: `English Language Test ${i + 1}` })) },
          { name: 'Statistics', tests: Array.from({ length: 25 }, (_, i) => ({ id: `t2-st-${i + 1}`, name: `Statistics Test ${i + 1}` })) },
        ],
      },
    }
  };

  return <ExamTestSelector {...sscCGLData} />;
};

export default SSCCGLTestSelectorPage;