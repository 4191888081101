import React, { useState, useCallback } from 'react';
import Footer from '../components/Footer';
import { Container, Title } from '../styles/StyledComponents';
const styles = {
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '2rem',
    fontFamily: '"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    backgroundColor: '#f0f4f8',
    borderRadius: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  heading1: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
    color: '#2c3e50',
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: '2px',
  },
  subheading: {
    fontSize: '1.2rem',
    color: '#34495e',
    textAlign: 'center',
    marginBottom: '2rem',
    fontStyle: 'italic',
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '2rem',
  },
  tabButton: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginRight: '1rem',
    padding: '0.75rem 1.5rem',
    border: '1px solid #3498db',
    borderRadius: '8px',
    backgroundColor: '#fff',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  activeTabButton: {
    backgroundColor: '#3498db',
    color: '#fff',
  },
  tabContent: {
    padding: '1rem',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    marginBottom: '2rem',
  },
  heading2: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginTop: '2rem',
    marginBottom: '1rem',
    color: '#2c3e50',
    borderBottom: '2px solid #3498db',
    paddingBottom: '0.5rem',
  },
  heading3: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginTop: '1.5rem',
    marginBottom: '1rem',
    color: '#fff',
    cursor: 'pointer',
    backgroundColor: '#3498db',
    padding: '0.75rem',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: 'none',
    width: '100%',
    textAlign: 'left',
    transition: 'background-color 0.3s ease',
  },
  heading3Hover: {
    backgroundColor: '#2980b9',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '1.5rem',
    marginTop: '1rem',
    maxHeight: '400px',
    overflowY: 'auto',
    padding: '1rem',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  card: {
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  },
  cardHover: {
    transform: 'translateY(-5px)',
    boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
  },
  cardTitle: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
    color: '#2c3e50',
  },
  button: {
    padding: '0.75rem',
    backgroundColor: '#2ecc71',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '1rem',
    transition: 'background-color 0.3s ease',
    fontWeight: 'bold',
  },
  buttonHover: {
    backgroundColor: '#27ae60',
  },
  selectedTest: {
    marginTop: '2rem',
    padding: '1rem',
    backgroundColor: '#e8f6f3',
    borderRadius: '4px',
    color: '#27ae60',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '1.2rem',
  },
};

const ExamTestSelector = ({ 
  examName,
  examAbbreviation,
  introText,
  whyOurTests,
  aboutExam,
  motivationText,
  testData
}) => {
  const [selectedTest, setSelectedTest] = useState(null);
  const [expandedSections, setExpandedSections] = useState({});
  const [activeTab, setActiveTab] = useState('intro');
  const [hoveredButton, setHoveredButton] = useState(null);
  const [hoveredCard, setHoveredCard] = useState(null);

  const handleTestSelection = useCallback((testId) => {
    setSelectedTest(testId);
    console.log(`Selected test: ${testId}`);
  }, []);

  const renderTabContent = useCallback(() => {
    switch (activeTab) {
      case 'intro':
        return (
          <div style={styles.tabContent}>
            <p>{introText}</p>
          </div>
        );
      case 'why':
        return (
          <div style={styles.tabContent}>
            <p>{whyOurTests.intro}</p>
            <ul>
              {whyOurTests.points.map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
          </div>
        );
      case 'about':
        return (
          <div style={styles.tabContent}>
            <p>{aboutExam.intro}</p>
            <ul>
              {aboutExam.structure.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            <p>{aboutExam.outro}</p>
          </div>
        );
      case 'motivation':
        return (
          <div style={styles.tabContent}>
            <p>{motivationText}</p>
          </div>
        );
      default:
        return null;
    }
  }, [activeTab, introText, whyOurTests, aboutExam, motivationText]);

  const toggleSection = useCallback((sectionId) => {
    setExpandedSections(prev => ({
      ...prev,
      [sectionId]: !prev[sectionId]
    }));
  }, []);

  const renderTests = useCallback((tests) => {
    return (
      <div style={styles.grid}>
        {tests.map((test) => (
          <div
            key={test.id}
            style={{
              ...styles.card,
              ...(hoveredCard === test.id ? styles.cardHover : {}),
            }}
            onMouseEnter={() => setHoveredCard(test.id)}
            onMouseLeave={() => setHoveredCard(null)}
          >
            <h4 style={styles.cardTitle}>{test.name}</h4>
            <button
              onClick={() => handleTestSelection(test.id)}
              style={{
                ...styles.button,
                ...(hoveredButton === test.id ? styles.buttonHover : {}),
              }}
              onMouseEnter={() => setHoveredButton(test.id)}
              onMouseLeave={() => setHoveredButton(null)}
              aria-label={`Start ${test.name}`}
            >
              Start Test
            </button>
          </div>
        ))}
      </div>
    );
  }, [handleTestSelection]);

  const renderTier = useCallback((tier, tierName) => (
    <div key={tierName}>
      <h2 style={styles.heading2}>{tierName}</h2>
      <div>
        <button
          onClick={() => toggleSection(`${tierName}-full`)}
          style={{
            ...styles.heading3,
            ...(hoveredButton === `${tierName}-full` ? styles.heading3Hover : {})
          }}
          onMouseEnter={() => setHoveredButton(`${tierName}-full`)}
          onMouseLeave={() => setHoveredButton(null)}
          aria-expanded={expandedSections[`${tierName}-full`]}
        >
          {tierName} Full Mock Tests
          <span>{expandedSections[`${tierName}-full`] ? '▼' : '▶'}</span>
        </button>
        {expandedSections[`${tierName}-full`] && renderTests(tier.fullTests)}
      </div>
      {tier.sections.map((section) => (
        <div key={section.name}>
          <button
            onClick={() => toggleSection(`${tierName}-${section.name}`)}
            style={{
              ...styles.heading3,
              ...(hoveredButton === `${tierName}-${section.name}` ? styles.heading3Hover : {})
            }}
            onMouseEnter={() => setHoveredButton(`${tierName}-${section.name}`)}
            onMouseLeave={() => setHoveredButton(null)}
            aria-expanded={expandedSections[`${tierName}-${section.name}`]}
          >
            {tierName} {section.name}
            <span>{expandedSections[`${tierName}-${section.name}`] ? '▼' : '▶'}</span>
          </button>
          {expandedSections[`${tierName}-${section.name}`] && renderTests(section.tests)}
        </div>
      ))}
    </div>
  ), [expandedSections, renderTests, toggleSection]);

  return (
    <div style={styles.container}>
      <h1 style={styles.heading1}>{examName} Previous Year test papers</h1>
      <p style={styles.subheading}>Prepare smarter, score higher!</p>

      {/* Tabbed Interface for Top Section */}
      <div style={styles.tabContainer}>
        <button
          style={{ 
            ...styles.tabButton, 
            ...(activeTab === 'intro' ? styles.activeTabButton : {}) 
          }}
          onClick={() => setActiveTab('intro')}
        >
          Introduction
        </button>
        <button
          style={{ 
            ...styles.tabButton, 
            ...(activeTab === 'why' ? styles.activeTabButton : {}) 
          }}
          onClick={() => setActiveTab('why')}
        >
          Why Our Tests
        </button>
        <button
          style={{ 
            ...styles.tabButton, 
            ...(activeTab === 'about' ? styles.activeTabButton : {}) 
          }}
          onClick={() => setActiveTab('about')}
        >
          About the Exam
        </button>
        <button
          style={{ 
            ...styles.tabButton, 
            ...(activeTab === 'motivation' ? styles.activeTabButton : {}) 
          }}
          onClick={() => setActiveTab('motivation')}
        >
          Motivation
        </button>
      </div>

      {/* Content for the selected tab */}
      {renderTabContent()}

      {/* Test Selection Section */}
      {Object.entries(testData).map(([tierName, tierData]) =>
        renderTier(tierData, tierName)
      )}

      {selectedTest && (
        <div style={styles.selectedTest} role="status" aria-live="polite">
          You've selected test: {selectedTest}. Good luck!
        </div>
      )}
      <Footer />
    </div>
  );
};

export default ExamTestSelector;
